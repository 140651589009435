import React from "react"
import { Link } from "gatsby"
import { Box, Badge, Image, Grid } from "@chakra-ui/core"
import Layout from "../../../components/layoutPhoenixCustom"
import BgImg from "../../../images/PerfectChristmas_Big.jpg"
import PerfectChristmas from "../../../images/PerfectChristmas_Square.jpg"
import More from "../../../images/moreSermons.jpg"
import SEO from "../../../components/seo"
import { css } from "@emotion/core"

function SermonBox({
    imageUrl,
    imageAlt,
    sermonTitle,
    sermonLink,
    date,
    badge,
    pastor,
    sermonSeries,
    badgeColor,
}) {
    return (
        <Box
            as={Link}
            to={sermonLink}
            w="auto"
            h="auto"
            borderWidth="1px"
            rounded="lg"
            overflow="hidden"
            bg="#edf2f7"
            boxShadow="md"
            css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
        >
            <Image src={imageUrl} alt={imageAlt} w="100%" />

            <Box p="6">
                <Box alignItems="baseline">
                    <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
                        {badge}
                    </Badge>
                    <Box
                        color="gray.500"
                        fontWeight="semibold"
                        letterSpacing="wide"
                        fontSize="xs"
                        textTransform="uppercase"
                        mt="3"
                    >
                        {sermonSeries} &bull; {pastor}
                    </Box>
                </Box>
                <Box
                    fontWeight="bold"
                    as="h2"
                    lineHeight="tight"
                    isTruncated
                    color="gray.900"
                >
                    {sermonTitle}
                </Box>

                <Box color="gray.600" fontSize="sm">
                    {date}
                </Box>
            </Box>
        </Box>
    )
}

function SermonBoxExternal({
    imageUrl,
    imageAlt,
    sermonTitle,
    sermonLink,
    date,
    badge,
    pastor,
    sermonSeries,
    badgeColor,
}) {
    return (
        <Box
            as="a"
            href={sermonLink}
            w="auto"
            h="auto"
            borderWidth="1px"
            rounded="lg"
            overflow="hidden"
            bg="#edf2f7"
            boxShadow="md"
            css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
        >
            <Image src={imageUrl} alt={imageAlt} w="100%" />

            <Box p="6">
                <Box alignItems="baseline">
                    <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
                        {badge}
                    </Badge>
                    <Box
                        color="gray.500"
                        fontWeight="semibold"
                        letterSpacing="wide"
                        fontSize="xs"
                        textTransform="uppercase"
                        mt="3"
                    >
                        {sermonSeries} &bull; {pastor}
                    </Box>
                </Box>
                <Box
                    fontWeight="bold"
                    as="h2"
                    lineHeight="tight"
                    isTruncated
                    color="gray.900"
                >
                    {sermonTitle}
                </Box>

                <Box color="gray.600" fontSize="sm">
                    {date}
                </Box>
            </Box>
        </Box>
    )
}

const IndexPage = () => (
    <Layout
        ImgSrc={BgImg}
        MainText="Searching for the Perfect Christmas"
        SecondText="Check out sermons from our Searching for the Perfect Christmas sermon series"
    >
        <SEO title="Sermons - Searching for the Perfect Christmas" />
        <Grid
            templateColumns={[
                "repeat(1, 1fr)",
                "repeat(1, 1fr)",
                "repeat(2, 1fr)",
                "repeat(3, 1fr)",
            ]}
            gap={10}
        >
            <SermonBox
                imageUrl={PerfectChristmas}
                imageAlt="Searching for the Perfect Christmas Series"
                sermonTitle="Christmas Eve"
                sermonSeries="Searching for the Perfect Christmas"
                sermonLink="/phoenix/sermons/christmas-eve-2023"
                date="December 24th, 2023"
                pastor="Pastor Andrew"
            />
            <SermonBox
                imageUrl={PerfectChristmas}
                imageAlt="Searching for the Perfect Christmas Series"
                sermonTitle="Preparation"
                sermonSeries="Searching for the Perfect Christmas"
                sermonLink="/phoenix/sermons/preparation"
                date="December 17th, 2023"
                pastor="Pastor Andrew"
            />
            <SermonBox
                imageUrl={PerfectChristmas}
                imageAlt="Searching for the Perfect Christmas Series"
                sermonTitle="Celebration"
                sermonSeries="Searching for the Perfect Christmas"
                sermonLink="/phoenix/sermons/celebration"
                date="December 10th, 2023"
                pastor="Pastor Andrew"
            />
            <SermonBox
                imageUrl={PerfectChristmas}
                imageAlt="Searching for the Perfect Christmas Series"
                sermonTitle="Anticipation"
                sermonSeries="Searching for the Perfect Christmas"
                sermonLink="/phoenix/sermons/anticipation"
                date="December 3rd, 2023"
                pastor="Pastor Andrew"
            />
            <SermonBox
                imageUrl={More}
                imageAlt="view more sermons"
                sermonTitle="View More Sermons"
                sermonLink="/phoenix/sermons"
                sermonSeries="All Sermons"
                pastor="Cross Church Phoenix"
            />
        </Grid>
    </Layout>
)

export default IndexPage
